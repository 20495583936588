import { render } from "./AppBtn.vue?vue&type=template&id=51f33ed5"
import script from "./AppBtn.vue?vue&type=script&lang=ts"
export * from "./AppBtn.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./AppBtn.vue?vue&type=style&index=0&id=51f33ed5&lang=scss&module=true"
cssModules["$style"] = style0
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
