import axios from 'axios'

export interface ExportParams {
  name: string
  sheets: Sheet[]
}

export interface Sheet {
  name: string
  data: unknown[]
}

export const exportExcel = async ({
  name,
  sheets,
}: ExportParams): Promise<void> => {
  const filename = `${name}.xlsx`
  try {
    const resp = await axios({
      url: 'https://spreadsheet-generator.reges-services.se/xlsx',
      method: 'POST',
      responseType: 'arraybuffer',
      auth: {
        username: 'dced0cdb-1a9f-40c8-aac0-1e674b8e1975',
        password: 'c0529f0b-3e9d-4873-9a9d-27a14739b83c',
      },
      data: {
        name,
        sheets,
      },
    })

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(resp.data, filename)
    } else {
      const a = document.createElement('a')
      const url = window.URL.createObjectURL(
        new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      )
      a.href = url
      a.target = '_blank'
      a.download = filename
      document.body.appendChild(a)
      a.click()
    }
  } catch (err) {
    const arr = new Uint8Array(err.response.data)
    const str = JSON.parse(String.fromCharCode(...arr))
    throw { ...str, isAxiosError: true }
  }
}
