import { render } from "./WashListTable.vue?vue&type=template&id=1538b591"
import script from "./WashListTable.vue?vue&type=script&lang=ts"
export * from "./WashListTable.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QTable,QSeparator,QInput,QIcon,QPopupProxy,QDate});
