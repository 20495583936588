<template>
  <q-card style="width: 400px">
    <q-form ref="formRef" @submit.prevent="onImport" autocomplete="off" greedy>
      <q-card-section>
        <div class="text-h6">Importera tvättlista</div>
      </q-card-section>
      <q-separator />
      <q-card-section> Importera .CSV tvättlista </q-card-section>
      <q-slide-transition>
        <q-card-section v-if="isWashListOnDate" class="text-red-10">
          <q-icon name="mdi-information-outline" />
          Det finns redan en tvättlista importerad på detta datum, är du säker
          på att du vill skriva över tvättlistan?
        </q-card-section>
      </q-slide-transition>
      <q-card-section>
        <div class="row">
          <div class="col q-mb-md">
            <q-input
              outlined
              square
              mask="####-##-##"
              v-model="importDate"
              :rules="[validDateRule]"
            >
              <template v-slot:prepend>
                <q-icon name="mdi-calendar" class="cursor-pointer">
                  <q-popup-proxy
                    ref="datePopupRef"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      first-day-of-week="1"
                      mask="YYYY-MM-DD"
                      today-btn
                      @update:model-value="datePopupRef?.hide"
                      v-model="importDate"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <q-file
              :loading="uploading"
              v-model="importWashListFile"
              label="Välj .CSV Tvättlista"
              outlined
              square
              accept=".csv"
              :clearable="!uploading"
              :rules="[requiredRule]"
              style="max-width: 400px"
            >
              <template v-slot:file="{ file }">
                <q-chip class="full-width q-my-xs">
                  {{ file.name }}
                </q-chip>
              </template>
            </q-file>
          </div>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="$emit('close')"
          :disable="uploading"
        />
        <q-btn
          flat
          label="Importera"
          color="primary"
          type="submit"
          :disable="fetchingWashList"
          :loading="uploading"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useWashList } from '@/composables/useWashList'
import { uploadWashList } from '@/api/upload/uploadWashList'
import {
  validDateRule,
  requiredRule,
  dateFormatRegEx,
} from '@/common/formValidationRules'
import { format } from 'date-fns'
import { QForm } from 'quasar'
import { getWashList } from '@/api/wash-list/getWashList'

export default defineComponent({
  name: 'WashListImportModal',

  emits: ['close'],

  setup(props, { emit }) {
    const formRef = ref<QForm | null>(null)
    const fetchingWashList = ref(false)
    const isWashListOnDate = ref(false)
    const importDate = ref(format(new Date(), 'yyyy-MM-dd'))
    const datePopupRef = ref(null)
    const { date, fetchWashList } = useWashList()
    const importWashListFile = ref<File | null>(null)
    const uploading = ref(false)

    if (dateFormatRegEx.test(date.value)) {
      importDate.value = date.value
    }

    watch(
      importDate,
      async () => {
        if (!dateFormatRegEx.test(importDate.value)) return
        fetchingWashList.value = true
        const washList = await getWashList(importDate.value)
        if (washList.length) {
          isWashListOnDate.value = true
        } else {
          isWashListOnDate.value = false
        }
        fetchingWashList.value = false
      },
      {
        immediate: true,
      }
    )

    async function onImport() {
      const isValid = await formRef.value?.validate()
      if (!isValid || !importWashListFile.value) return
      uploading.value = true
      const formData = new FormData()
      formData.append('file', importWashListFile.value)
      formData.append('trafficDate', importDate.value)
      try {
        await uploadWashList({ formData, date: importDate.value })
      } catch (error) {
        console.log(error)
      } finally {
        date.value = importDate.value
        await fetchWashList(importDate.value)
        uploading.value = false
        emit('close')
      }
    }

    return {
      formRef,
      importDate,
      importWashListFile,
      uploading,
      onImport,
      validDateRule,
      datePopupRef,
      requiredRule,
      isWashListOnDate,
      fetchingWashList,
    }
  },
})
</script>
