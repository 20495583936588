
import { computed, defineComponent, ref, watch } from 'vue'
import AppBtn from '@/components/AppBtn.vue'
import QTable from 'quasar/src/components/table/QTable.js';
import { exportExcel } from '@/services/export-excel'
import WashListImportModal from '@/components/wash-list/WashListImportModal.vue'
import { buildExcelSheetFromTable } from '@/common/buildExcelSheetFromTable'
import { useWashList } from '@/composables/useWashList'
import { dateFormatRegEx } from '@/common/formValidationRules'
import { format } from 'date-fns'
import { Arrival } from '@/types/arrival'
import capitalize from 'lodash.capitalize'

export default defineComponent({
  name: 'WashListTable',

  components: {
    AppBtn,
    WashListImportModal,
  },

  setup() {
    const { date, loading, fetchWashList, data: washList } = useWashList()
    const exporting = ref(false)
    const showImportModal = ref(false)
    const datePopupRef = ref(null)
    const isValidDate = computed(() => dateFormatRegEx.test(date.value))

    const columns: QTable['columns'] = [
      {
        name: 'trafficDate',
        label: 'Trafikdatum',
        field: 'trafficDate',
        align: 'left',
        sortable: true,
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
      },
      {
        name: 'vehicle',
        label: 'Fordon',
        field: 'vehicle',
        align: 'left',
        sortable: true,
      },
      {
        name: 'location',
        label: 'Plats',
        field: 'location',
        align: 'left',
        sortable: true,
      },
      {
        name: 'arrivalTime',
        label: 'Ankomst',
        field: 'arrivalTime',
        align: 'left',
        sortable: true,
      },
      {
        name: 'sick',
        label: 'Sjuk',
        field: 'sick',
        align: 'left',
        format: (v: boolean) => (v ? 'Ja' : ''),
        sortable: true,
      },
      {
        name: 'cleaning',
        label: 'Storstäd',
        field: 'cleaning',
        format: (v: boolean) => (v ? 'Ja' : ''),
        align: 'left',
        sortable: true,
      },
      {
        name: 'reserve',
        label: 'Reserv',
        field: 'reserve',
        format: (v: boolean) => (v ? 'Ja' : ''),
        align: 'left',
        sortable: true,
      },
      {
        name: 'status',
        label: 'Status',
        field: (row: Arrival) => row.status?.status,
        format: (v: string | null) => (v ? capitalize(v) : ''),
        align: 'left',
        sortable: true,
      },
    ]

    watch(
      date,
      async () => {
        if (!isValidDate.value) return
        await fetchWashList(date.value)
      },
      {
        immediate: true,
      }
    )

    const exportData = computed(() => {
      return buildExcelSheetFromTable({
        name: 'Tvättlista',
        table: washList.value,
        columns: columns,
      })
    })

    async function onExportExcel() {
      exporting.value = true
      await exportExcel(exportData.value)
      exporting.value = false
    }

    return {
      washList,
      exporting,
      onExportExcel,
      showImportModal,
      columns,
      date,
      isValidDate,
      datePopupRef,
      loading,
    }
  },
})
