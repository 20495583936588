import { Arrival } from '@/types/arrival'
import { ref, Ref } from 'vue'
import { getWashList } from '@/api/wash-list/getWashList'
import { format } from 'date-fns'

interface UseWashList {
  data: Ref<Arrival[]>
  date: Ref<string>
  loading: Ref<Loading>
  fetchWashList: (date: string) => Promise<Arrival[]>
}

interface Loading {
  getAll: boolean
}

const data = ref<Arrival[]>([])
const date = ref<string>(format(new Date(), 'yyyy-MM-dd'))
const loading = ref<Loading>({
  getAll: false,
})

export const useWashList = (): UseWashList => {
  async function fetchWashList(date: string) {
    loading.value.getAll = true
    try {
      const washList = await getWashList(date)
      data.value = washList
      return Promise.resolve(washList)
    } catch (error) {
      console.log(error)
      data.value = []
      return Promise.resolve([])
    } finally {
      loading.value.getAll = false
    }
  }

  return {
    fetchWashList,
    data,
    date,
    loading,
  }
}
