
import { defineComponent } from 'vue'
import WashListTable from '@/components/wash-list/WashListTable.vue'

export default defineComponent({
  name: 'WashList',

  components: {
    WashListTable,
  },
})
