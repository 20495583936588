<template>
  <q-dialog v-model="showImportModal" persistent>
    <WashListImportModal
      v-if="showImportModal"
      @close="showImportModal = false"
    />
  </q-dialog>
  <q-table
    dense
    :rows="washList"
    style="height: calc(100vh - 100px)"
    :loading="loading.getAll"
    :columns="columns"
    table-header-class="text-accent"
    class="stickyTableHeader"
    :rows-per-page-options="[0]"
  >
    <template v-slot:top>
      <div class="col q-gutter-none">
        <div class="row q-gutter-none">
          <div class="col q-pb-md q-pt-sm">
            <h6 class="q-ma-none text-accent">Tvättlista</h6>
          </div>
        </div>
        <q-separator />
        <div class="row q-gutter-none">
          <q-input
            hide-bottom-space
            borderless
            mask="####-##-##"
            v-model="date"
            :error="!isValidDate"
          >
            <template v-slot:prepend>
              <q-icon name="mdi-calendar" class="cursor-pointer">
                <q-popup-proxy
                  ref="datePopupRef"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    first-day-of-week="1"
                    mask="YYYY-MM-DD"
                    today-btn
                    @update:model-value="datePopupRef?.hide"
                    v-model="date"
                  />
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-separator vertical class="q-mx-md" />
          <div class="col">
            <q-input placeholder="Filtrera..." borderless>
              <template v-slot:prepend>
                <q-icon name="mdi-magnify" />
              </template>
            </q-input>
          </div>
          <q-separator vertical />
          <AppBtn
            :loading="exporting"
            @click="onExportExcel"
            label="Exportera"
            flat
            color="accent"
            icon-right="mdi-file-export-outline"
          />
          <AppBtn
            @click="showImportModal = true"
            label="Importera"
            color="primary"
            icon-right="mdi-file-import-outline"
          />
        </div>
        <q-separator />
      </div>
    </template>
  </q-table>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import AppBtn from '@/components/AppBtn.vue'
import { QTable } from 'quasar'
import { exportExcel } from '@/services/export-excel'
import WashListImportModal from '@/components/wash-list/WashListImportModal.vue'
import { buildExcelSheetFromTable } from '@/common/buildExcelSheetFromTable'
import { useWashList } from '@/composables/useWashList'
import { dateFormatRegEx } from '@/common/formValidationRules'
import { format } from 'date-fns'
import { Arrival } from '@/types/arrival'
import capitalize from 'lodash.capitalize'

export default defineComponent({
  name: 'WashListTable',

  components: {
    AppBtn,
    WashListImportModal,
  },

  setup() {
    const { date, loading, fetchWashList, data: washList } = useWashList()
    const exporting = ref(false)
    const showImportModal = ref(false)
    const datePopupRef = ref(null)
    const isValidDate = computed(() => dateFormatRegEx.test(date.value))

    const columns: QTable['columns'] = [
      {
        name: 'trafficDate',
        label: 'Trafikdatum',
        field: 'trafficDate',
        align: 'left',
        sortable: true,
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
      },
      {
        name: 'vehicle',
        label: 'Fordon',
        field: 'vehicle',
        align: 'left',
        sortable: true,
      },
      {
        name: 'location',
        label: 'Plats',
        field: 'location',
        align: 'left',
        sortable: true,
      },
      {
        name: 'arrivalTime',
        label: 'Ankomst',
        field: 'arrivalTime',
        align: 'left',
        sortable: true,
      },
      {
        name: 'sick',
        label: 'Sjuk',
        field: 'sick',
        align: 'left',
        format: (v: boolean) => (v ? 'Ja' : ''),
        sortable: true,
      },
      {
        name: 'cleaning',
        label: 'Storstäd',
        field: 'cleaning',
        format: (v: boolean) => (v ? 'Ja' : ''),
        align: 'left',
        sortable: true,
      },
      {
        name: 'reserve',
        label: 'Reserv',
        field: 'reserve',
        format: (v: boolean) => (v ? 'Ja' : ''),
        align: 'left',
        sortable: true,
      },
      {
        name: 'status',
        label: 'Status',
        field: (row: Arrival) => row.status?.status,
        format: (v: string | null) => (v ? capitalize(v) : ''),
        align: 'left',
        sortable: true,
      },
    ]

    watch(
      date,
      async () => {
        if (!isValidDate.value) return
        await fetchWashList(date.value)
      },
      {
        immediate: true,
      }
    )

    const exportData = computed(() => {
      return buildExcelSheetFromTable({
        name: 'Tvättlista',
        table: washList.value,
        columns: columns,
      })
    })

    async function onExportExcel() {
      exporting.value = true
      await exportExcel(exportData.value)
      exporting.value = false
    }

    return {
      washList,
      exporting,
      onExportExcel,
      showImportModal,
      columns,
      date,
      isValidDate,
      datePopupRef,
      loading,
    }
  },
})
</script>
