export const dateFormatRegEx =
  /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/

export const requiredNotNull = (val: unknown | null): boolean | string =>
  val !== null || 'Obligatorisk'

export const requiredRule = (val: string | number | null): boolean | string =>
  Boolean(val && val.toString().length > 0) || 'Obligatorisk'

export const booleanTrueRequired = (val: boolean): boolean | string =>
  val || 'Obligatorisk'

export const validDateRule = (val: string): boolean | string =>
  dateFormatRegEx.test(val) || 'Måste vara ett giltigt datum'

export const minCharactersRule =
  (minChar: number) =>
  (val: string | number | null): boolean | string =>
    Boolean(val && val.toString().length >= minChar) ||
    `Måste minst vara ${minChar} tecken`

export const requiredNotEmpty = (val: unknown[]): boolean | string =>
  Boolean(val.length) || 'Obligatorisk'
