import { instance } from '@/services/klarbuss-api'
import { AxiosPromise } from 'axios'

export interface UploadWashListParams {
  date: string
  formData: FormData
}

export const uploadWashList = ({
  formData,
  date,
}: UploadWashListParams): AxiosPromise<unknown> =>
  instance.post('/upload/wash-list', formData, {
    params: { date },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
