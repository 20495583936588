
import { defineComponent, ref, watch } from 'vue'
import { useWashList } from '@/composables/useWashList'
import { uploadWashList } from '@/api/upload/uploadWashList'
import {
  validDateRule,
  requiredRule,
  dateFormatRegEx,
} from '@/common/formValidationRules'
import { format } from 'date-fns'
import QForm from 'quasar/src/components/form/QForm.js';
import { getWashList } from '@/api/wash-list/getWashList'

export default defineComponent({
  name: 'WashListImportModal',

  emits: ['close'],

  setup(props, { emit }) {
    const formRef = ref<QForm | null>(null)
    const fetchingWashList = ref(false)
    const isWashListOnDate = ref(false)
    const importDate = ref(format(new Date(), 'yyyy-MM-dd'))
    const datePopupRef = ref(null)
    const { date, fetchWashList } = useWashList()
    const importWashListFile = ref<File | null>(null)
    const uploading = ref(false)

    if (dateFormatRegEx.test(date.value)) {
      importDate.value = date.value
    }

    watch(
      importDate,
      async () => {
        if (!dateFormatRegEx.test(importDate.value)) return
        fetchingWashList.value = true
        const washList = await getWashList(importDate.value)
        if (washList.length) {
          isWashListOnDate.value = true
        } else {
          isWashListOnDate.value = false
        }
        fetchingWashList.value = false
      },
      {
        immediate: true,
      }
    )

    async function onImport() {
      const isValid = await formRef.value?.validate()
      if (!isValid || !importWashListFile.value) return
      uploading.value = true
      const formData = new FormData()
      formData.append('file', importWashListFile.value)
      formData.append('trafficDate', importDate.value)
      try {
        await uploadWashList({ formData, date: importDate.value })
      } catch (error) {
        console.log(error)
      } finally {
        date.value = importDate.value
        await fetchWashList(importDate.value)
        uploading.value = false
        emit('close')
      }
    }

    return {
      formRef,
      importDate,
      importWashListFile,
      uploading,
      onImport,
      validDateRule,
      datePopupRef,
      requiredRule,
      isWashListOnDate,
      fetchingWashList,
    }
  },
})
