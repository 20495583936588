<template>
  <q-btn unelevated :class="$style.button" no-caps><slot /></q-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppBtn',
})
</script>

<style lang="scss" module>
.button {
  height: 56px;
  border-radius: 0px;
}
</style>
