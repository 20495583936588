<template>
  <q-page class="q-pa-md">
    <WashListTable />
  </q-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import WashListTable from '@/components/wash-list/WashListTable.vue'

export default defineComponent({
  name: 'WashList',

  components: {
    WashListTable,
  },
})
</script>
